import { ZodValidator } from './utils/zodValidator/ZodValidator'
import { BaseAPIClient } from './utils/baseAPIClient/BaseAPIClient'
import { Logger } from './utils/logger/Logger'
import { AmplifyAPIClient } from './utils/amplifyAPIClient/AmplifyAPIClient'
import { z } from 'zod'
import {
    createThreadRequestBodySchema,
    createThreadResponseBodySchema
} from '@speakable/speakable-service-contracts/lib/conversation'

export class ConversationAPIClient extends BaseAPIClient {
    private static apiName: string = 'conversation'

    constructor() {
        super(ConversationAPIClient.apiName, new AmplifyAPIClient(), new ZodValidator(), new Logger())
    }

    async createThread(body: z.infer<typeof createThreadRequestBodySchema> = undefined) {
        return this.post(
            '/thread',
            { requestBody: body },
            {
                requestBodySchema: createThreadRequestBodySchema,
                responseBodySchema: createThreadResponseBodySchema
            }
        )
    }
}
