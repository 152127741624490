import { ILogger } from './ILogger'

/**
 * A logger that does nothing (yet!).
 */
export class Logger implements ILogger {
    logError(error: string): void {}
    logInfo(message: string): void {}
    logWarn(message: string): void {}
}
