import {
    GetInput,
    GetOperation,
    PostInput,
    PostOperation,
    PutInput,
    PutOperation,
    PatchInput,
    PatchOperation,
    DeleteInput,
    DeleteOperation
} from '@aws-amplify/api-rest/dist/esm/types'
import { get, post, put, patch, del } from '@aws-amplify/api'
import { IAmplifyAPIClient } from './IAmplifyAPIClient'

export class AmplifyAPIClient implements IAmplifyAPIClient {
    get(input: GetInput): GetOperation {
        return get(input)
    }
    post(input: PostInput): PostOperation {
        return post(input)
    }
    put(input: PutInput): PutOperation {
        return put(input)
    }
    patch(input: PatchInput): PatchOperation {
        return patch(input)
    }
    del(input: DeleteInput): DeleteOperation {
        return del(input)
    }
}
