export type Response<TStatusCode extends number, TBody extends any> = {
    statusCode: TStatusCode
    body: TBody
}

export type InternalServerErrorResponse = Response<500, { message: string }>

export type BadRequestResponse = Response<400, { message: string }>

export type ClientClosedRequestResponse = Response<499, { message: string }>

export const internalServerErrorResponse = (message: string): InternalServerErrorResponse => ({
    statusCode: 500,
    body: { message }
})

export const badRequestResponse = (message: string): BadRequestResponse => ({ statusCode: 400, body: { message } })

export const clientClosedRequestResponse = (message: string): ClientClosedRequestResponse => ({
    statusCode: 499,
    body: { message }
})
