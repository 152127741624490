import { z } from 'zod'
import { get } from '@aws-amplify/api'
import { ILogger } from '../logger/ILogger'
import { IZodValidator } from '../zodValidator/IZodValidator'
import {
    BadRequestResponse,
    ClientClosedRequestResponse,
    InternalServerErrorResponse,
    badRequestResponse,
    clientClosedRequestResponse,
    internalServerErrorResponse
} from '../responses'
import { IAmplifyAPIClient } from '../amplifyAPIClient/IAmplifyAPIClient'
import { ResponseSchema } from '../../../api/utils/types'
import { isCancelError, post } from 'aws-amplify/api'
import { IBaseAPIClient } from './IBaseAPIClient'

/**
 * Base class for all API clients. It provides validation for input and output objects as well as the ability
 * to cancel requests early.
 */
export class BaseAPIClient implements IBaseAPIClient {
    protected apiName: string
    private amplifyAPIClient: IAmplifyAPIClient
    private zodValidator: IZodValidator
    private logger: ILogger

    /**
     * Creates a new instance of the BaseAPIClient class.
     *
     * @param apiName the name of the api in Amplify
     * @param zodValidator the zod validator to use for input and output validation
     * @param logger the logger to use for logging
     */
    constructor(apiName: string, amplifyAPIClient: IAmplifyAPIClient, zodValidator: IZodValidator, logger: ILogger) {
        this.apiName = apiName
        this.amplifyAPIClient = amplifyAPIClient
        this.zodValidator = zodValidator
        this.logger = logger
    }

    /**
     * @inheritdoc
     */
    public async get<TRequestQueryParams extends z.ZodTypeAny, TResponseBody extends ResponseSchema>(
        path: string,
        options: Omit<Parameters<typeof get>[0]['options'], 'queryParams'> & {
            requestQueryParams?: z.infer<TRequestQueryParams>
            abortSignal?: AbortSignal
        },
        validation: { requestQueryParamsSchema: TRequestQueryParams; responseBodySchema: TResponseBody }
    ): Promise<
        z.infer<TResponseBody> | InternalServerErrorResponse | BadRequestResponse | ClientClosedRequestResponse
    > {
        try {
            const queryParamsValidationResult = this.zodValidator.validate(
                options?.requestQueryParams,
                validation.requestQueryParamsSchema
            )
            if (queryParamsValidationResult === false) {
                this.logger.logError('Invalid query parameters.')
                return badRequestResponse('Invalid query parameters.')
            }

            const { response, cancel } = this.amplifyAPIClient.get({
                apiName: this.apiName,
                path,
                options: { ...options, queryParams: options.requestQueryParams }
            })
            if (options?.abortSignal) {
                options.abortSignal.onabort = () => {
                    cancel('Request was cancelled by the user.')
                }
            }

            const responseInformation = await response
            const body = { statusCode: responseInformation.statusCode, body: await (await response).body.json() }

            const responseBodyValidationResult = this.zodValidator.validate(body, validation.responseBodySchema)
            if (responseBodyValidationResult === false) {
                this.logger.logError('Invalid response body')
                return internalServerErrorResponse('Invalid response body')
            }

            return body as z.infer<TResponseBody>
        } catch (error) {
            if (isCancelError(error)) {
                this.logger.logInfo('Request was cancelled by the user.')
                return clientClosedRequestResponse('Request was cancelled by the user.')
            }

            this.logger.logError('An error occurred while processing the request.')
            return internalServerErrorResponse('An error occurred while processing the request.')
        }
    }

    /**
     * @inheritdoc
     */
    public async post<TRequestBody extends z.ZodTypeAny, TResponseBody extends ResponseSchema>(
        path: string,
        options: Omit<Parameters<typeof post>[0]['options'], 'body'> & {
            requestBody?: z.infer<TRequestBody>
            abortSignal?: AbortSignal
        },
        validation: { requestBodySchema: TRequestBody; responseBodySchema: TResponseBody }
    ): Promise<
        z.infer<TResponseBody> | InternalServerErrorResponse | BadRequestResponse | ClientClosedRequestResponse
    > {
        try {
            const queryParamsValidationResult = this.zodValidator.validate(
                options?.requestBody,
                validation.requestBodySchema
            )
            if (queryParamsValidationResult === false) {
                this.logger.logError('Invalid body.')
                return badRequestResponse('Invalid body.')
            }

            const { response, cancel } = this.amplifyAPIClient.post({
                apiName: this.apiName,
                path,
                options: { ...options, body: options.requestBody }
            })
            if (options?.abortSignal) {
                options.abortSignal.onabort = () => {
                    cancel('Request was cancelled by the user.')
                }
            }

            const responseInformation = await response
            const body = { statusCode: responseInformation.statusCode, body: await (await response).body.json() }

            const responseBodyValidationResult = this.zodValidator.validate(body, validation.responseBodySchema)
            if (responseBodyValidationResult === false) {
                this.logger.logError('Invalid response body')
                return internalServerErrorResponse('Invalid response body')
            }

            return body as z.infer<TResponseBody>
        } catch (error) {
            if (isCancelError(error)) {
                this.logger.logInfo('Request was cancelled by the user.')
                return clientClosedRequestResponse('Request was cancelled by the user.')
            }

            this.logger.logError('An error occurred while processing the request.')
            return internalServerErrorResponse('An error occurred while processing the request.')
        }
    }
}
