import { MutableRefObject, createContext } from 'react'
import { Referrer } from '../../store/site/data'

export type Tab = 'Product' | 'Features' | 'Story' | 'Pricing' | 'Contact'

export type LandingPageContextType = {
    navbarRef: MutableRefObject<HTMLElement | undefined>
    productRef: MutableRefObject<HTMLElement | undefined>
    featuresRef: MutableRefObject<HTMLElement | undefined>
    storyRef: MutableRefObject<HTMLElement | undefined>
    pricingRef: MutableRefObject<HTMLElement | undefined>
    contactRef: MutableRefObject<HTMLElement | undefined>
    currentlySelectedTab: Tab
    onTabClick: (tab: Tab) => void
    showSidebar: boolean
    setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>
}

export const LandingPageContext = createContext<LandingPageContextType | undefined>(undefined)

// maps the source string to the referrer enum
export const referrerMap: Map<string, Referrer> = new Map([
    ['googleads', Referrer.GOOGLE_ADS],
    ['demo-video-general', Referrer.DEMO_VIDEO_GENERAL],
    ['demo-video-caregiver', Referrer.DEMO_VIDEO_CAREGIVER],
    ['email-newsletter', Referrer.EMAIL_NEWSLETTER],
    ['news1-general', Referrer.NEWS1_GENERAL],
    ['news1-caregiver', Referrer.NEWS1_CAREGIVER],
    ['news2-general', Referrer.NEWS2_GENERAL],
    ['news2-caregiver', Referrer.NEWS2_CAREGIVER]
])
