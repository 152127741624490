import { z } from 'zod'
import { IZodValidator } from './IZodValidator.js'

/**
 * A validator that uses the Zod library to validate objects.
 */
export class ZodValidator implements IZodValidator {
    validate(payload: any, schema: z.ZodTypeAny): boolean {
        const result = schema.safeParse(payload)
        return result.success
    }
}
